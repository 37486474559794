.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4em auto;
  width: 70%;
}
.info__item {
  margin-bottom: 4em;
}

.info__item--header > i {
  font-size: 50px;
  color: #537bdf;
}
.info__item--header > h2 {
  font-weight: bold;
  color: #537bdf;
  margin: 1em 0;
}

.info__item--body {
  line-height: 1.9;
}
