.cards {
  margin: 2em 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: row;
  width: 450px;
  max-width: 100%;
  border-radius: 20px;
  border: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  text-align: left;
  margin: 1em 0.5em;
}
.card__image {
  background: linear-gradient(135deg, #afc6ff, #2657d0);
  padding: 1em;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__title {
}
.card__desc {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.card__footer {
  display: flex;
  justify-content: space-between;
}

.card__image > img {
  width: 80px;
  filter: blur(0.5px);
  -webkit-filter: blur(0.5px);
}

@media only screen and (max-width: 768px) {
  .card {
    width: 250px;
    flex-direction: column;
  }
}
