.footer {
  background: #3d5a80;
  text-align: left;
  color: white;
}

.footer h2 {
  margin-bottom: 0.5em;
  font-weight: bold;
}

.footer > .wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2em 0;
}

.footer__info > ul,
.footer__menu > ul {
  padding: 0;
  margin: 0;
}

.footer__menu > ul > li > a {
  color: white;
}
.footer__info > ul > li,
.footer__menu > ul > li {
  list-style: none;
  padding: 0.5em 0;
}

.footer__info i {
  width: 15px;
  margin-right: 5px;
}

.footer__logo > img {
  width: 200px;
  margin-top: -0.5em;
}

@media only screen and (max-width: 624px) {
  .footer__info,
  .footer__menu {
    margin-bottom: 2em;
  }

  .footer__logo {
    width: 100%;
    text-align: center;
  }
}
