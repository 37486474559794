.App {
  text-align: center;
  overflow: hidden;
  position: relative;
}

.wrap {
  width: 90%;
  max-width: 1144px;
  margin: 0 auto;
}

/* @media (prefers-color-scheme: dark) {
  body {
    background-color: #1f1f1f;
    color: #f1eded;
  }
} */
