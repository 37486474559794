.form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2em;
}
.form__content {
  width: 49%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 10px;
}

.custom-file-label::after { content: "Buscar...";}

.spinner-border {
  width: 1rem;
  height: 1rem;
  border: 3px solid currentColor;
  border-right-color: transparent;
  margin-right: 5px;
}


@media only screen and (max-width: 600px) {
  .form__content {
    width: 100%;
  }
}