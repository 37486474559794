:root {
  --baseBackground: #fff;
  --cardGray: #fff;
  --baseColor: #1d2029;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --baseBackground: #1d2029;
    --cardGray: #252b43;
    --baseColor: #fff;
  }
} */

.accesos {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 4em 0;
}

.acceso {
  background: var(--cardGray);
  color: var(--baseColor);
  width: 180px;
  height: 250px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.acceso__header {
  padding: 0.3em 0;
  background: linear-gradient(135deg, #0575e6, #021b79);
  color: white;
  border-radius: 20px;
  position: absolute;
  width: 100%;
  height: 80px;
  transition: height 0.15s ease-out;
  overflow: hidden;
  font-size: 40px;
}
.acceso__header i {
  transition: font-size 0.25s ease-in-out;
}
.acceso:hover .acceso__header {
  padding: 0;
}
.acceso__header--desc > p {
  margin: 0;
  padding: 0;
  margin-bottom: 0.5em;
}
.acceso:hover .acceso__header--desc {
  padding-top: 0;
}
.acceso__header--desc {
  font-size: 14px;
  padding: 0 0.5em;
  margin: 0;
  padding-top: 1em;
  transition: height 0.25s ease-in-out;
}

.acceso:hover .acceso__header {
  /* height: 100%; */
  height: 250px;
}
.acceso:hover .acceso__header i {
  font-size: 30px;
}
.acceso__body {
  font-weight: 300;
  padding: 0px 0.5em;
}
.acceso__body > p {
  margin-top: 7.5em;
}

.acceso__footer {
  padding: 1em;
}

.home__nosotros {
  padding: 4em 0;
  text-align: center;
}

.home__nosotros > h2 {
  font-family: "Playfair Display", "Times New Roman", Times, serif;
  font-weight: bold;
}

.home__items {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 4em;
}
.home__item {
  width: 200px;
  margin-bottom: 3em;
}

.home__item--header > i {
  font-size: 50px;
  color: #537bdf;
}
.home__item--header > h2 {
  font-weight: bold;
  color: #537bdf;
  margin: 1em 0;
}

.home__item--body {
  margin-bottom: 1em;
}

.home__info {
  display: flex;
  justify-content: space-between;
  padding: 4em 0;
  flex-flow: row wrap;
}

.home__notif,
.home__mapa {
  width: 48%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  overflow: hidden;
}

.home__notif {
  display: flex;
  align-items: center;
}

.home__icon {
  background: linear-gradient(135deg, #afc6ff, #2657d0);
  height: 100%;
  border-radius: 20px;
  flex: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  font-size: 30px;
}
.home__notif > p {
  flex: 4;
  padding: 0 1em;
  text-align: left;
  line-height: 1.8;
}

.home__mapa {
  display: flex;
  align-items: center;
}

.home__mapa--img {
  flex: 1;
  background-image: url("/img/map.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.home__mapa--desc {
  text-align: left;
  flex: 3;
  padding: 1em;
}

@media only screen and (max-width: 823px) {
  .home__notif,
  .home__mapa {
    width: 100%;
    margin-bottom: 2em;
  }
}

@media only screen and (max-width: 375px) {
  .accesos {
    justify-content: center;
  }
}
