.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
  position: relative;
}

.header__logo {
  width: 200px;
}

.header__menu {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 100%;
  transition: all 0.3s ease-in-out;
}
.header__menu > li {
  list-style: none;
}

.header__menu > li > a {
  text-decoration: none;
  transition: 0.3s ease-in-out;
  color: #333;
}
.header__menu > li > a:hover {
  color: #0575e6;
}

.active {
  color: #0575e6 !important;
  font-weight: bold;
}
.header__menu--checkbox {
  display: none;
}
.header__menu--button {
  display: block;
  width: 30px;
  height: 30px;
  background: url("/img/menu.svg") no-repeat center;
  /* transition: all 0.2s ease-in-out; */
}
.header__menu--checkbox:checked ~ .header__menu--button {
  background: url("/img/close.svg") no-repeat center;
}

.header__menu--checkbox:checked ~ .header__menu {
  display: block;
  position: absolute;
  top: 5em;
  padding: 1em;
  left: 0;
  right: 0;
  height: 100vh;
  background: linear-gradient(to bottom, #333 0%, transparent 90%);
}

.header__menu--checkbox:checked ~ .header__menu > li:first-of-type a {
  padding-top: 2em;
  border-radius: 0.5em 0.5em 0 0;
}
.header__menu--checkbox:checked ~ .header__menu > li:last-of-type a {
  padding-bottom: 2em;
  border-radius: 0 0 0.5em 0.5em;
}

.header__menu--checkbox:checked ~ .header__menu > li > a {
  background: white;
  color: var(--darkBlue);
  font-size: 1.1em;
  padding: 0.8em 1em;
  display: block;
  text-align: center;
  flex: 1;
}

@media only screen and (min-width: 720px) {
  .header__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    background: none;
    width: initial;
    height: auto;
    position: relative;
    left: 0;
  }
  .header__menu > li {
    margin-left: 2.5em;
  }
  .header__menu--button {
    display: none;
  }
}
