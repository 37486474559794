.slider {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 250px;
  i {
    font-size: 2em;
  }
}
@media only screen and (max-width: 792px) {
  .slider {
    height: 200px;
  }
}

.slide {
  min-width: 100%;
  transition: 0.5s;
  overflow: hidden;
  position: relative;
}

.thumb {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  &__content {
    display: flex;
    cursor: pointer;
    width: 10%;
    margin: 1em;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.3s;
    border: 2px solid white;
  }
  & img {
    width: 100%;
    flex: 1;
  }
}
@media only screen and (max-width: 767px) {
  .thumb {
    display: none;
  }
}

%btn-styles {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  transition: 0.3s;
  &:hover {
    background: rgba($color: #000000, $alpha: 0.356);
    cursor: pointer;
    i {
      color: white;
    }
  }
}

#goLeft {
  left: 0;
  @extend %btn-styles;
}

#goRight {
  right: 0;
  @extend %btn-styles;
}
