body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 14px;
}

h1 {
  font-family: "Playfair Display", "Times New Roman", Times, serif;
  font-weight: bold;
  margin: 0;
}

h2 {
  margin: 0;
  font-size: 17px;
  font-weight: 400;
}

a {
  text-decoration: none !important;
  color: initial;
}

.button {
  display: inline-block;
  background: none;
  border: 1px solid #2657d0;
  color: #2657d0;
  padding: 0.5em 1em;
  border-radius: 1000px;
  transition: 0.3s ease-in-out;
  outline: none;
  cursor: pointer;
}

.button:hover {
  background: #2657d0;
  color: white;
}

.form-check {
  margin-right: 2em;
}