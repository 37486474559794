.hero {
  display: flex;
  justify-content: space-between;
  /* height: 60vh; */
  padding-top: 2em;
  padding-bottom: 4em;
  align-items: center;
  position: relative;
  /* background-image: linear-gradient(to right, white, transparent); */
}

.hero:before {
  content: "";
  position: absolute;
  /* background: linear-gradient(to right, rgba(255, 255, 255, 0.6), transparent)
      left no-repeat,
    url("/img/hero.jpeg") right no-repeat; */
    background: linear-gradient(90deg,#f0f2f0,#000c40) 0 no-repeat;
  z-index: -1;
  width: 70%;
  height: calc(100% + 90px);
  border-radius: 0 0 0px 100px;
  right: 0;
  top: -86px;
}

.hero > .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2em 0;
}

/* .hero__image {
  position: relative;
  overflow: hidden;
  flex: 1;
} */

.hero__description {
  text-align: left;
  flex: 1;
  margin-right: 8em;
}
.hero__notif {
  flex: 1;
  box-shadow: 0 20px 44px rgba(0, 0, 0, 0.35);
  border-radius: 20px;
  overflow: hidden;
  background: white;
}
.hero__body {
  margin: 0;
  padding: 1em;
  line-height: 1.6;
}
.hero__header {
  background: linear-gradient(135deg, #afc6ff, #2657d0);
  flex: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  font-size: 25px;
  padding: 0.5em;
}
.hero__description > h1 {
  font-size: 4.5em;
}

.hero__description > p {
  line-height: 1.9;
  font-weight: bold;
}

.hero__input {
  display: flex;
}

.hero__input > .btn {
  background-color: #2657d0;
  border: 1px solid #2657d0;
  width: 200px;
  text-transform: uppercase;
  font-weight: 300;
  -webkit-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
.hero__input > .btn:hover {
  border: 1px solid #021b79;
  background-color: #021b79;
}

.hero__input > .btn > i {
  margin-right: 10px;
}

@media only screen and (max-width: 730px) {
  .hero > .wrap {
    flex-direction: column;
  }
  .hero__description {
    margin-right: 0;
    margin-bottom: 4em;
  }
}
@media only screen and (max-width: 500px) {
  .hero__description > h1 {
    font-size: 40px !important;
  }
  .hero__description {
    width: 100%;
  }
}

/* @media (prefers-color-scheme: dark) {
  .hero:before {
    background: linear-gradient(to right, rgba(31, 31, 31, 0.6), transparent)
        left no-repeat,
      url("/img/hero.jpeg") right no-repeat;
  }
} */
